import store from "../store";

export default {
    data() {
        return {
            isAuth: window.AyanCart.loggedIn,
            addingToCart: false,
            cartItem: false,
            cart: store.state.cart,
            isToggled: this.product.status, // <-- New data property for toggle state
        };
    },
    created() {
        this.unwatchCart = this.$watch(
            () => store.state.cart,
            (newCart, oldCart) => {
                this.cart = newCart;
                this.cartItem = store.getProductFromCart(this.product.id);
            },
            { deep: true } // Enable deep watching for nested properties
        );
    },
    destroyed() {
        if (this.unwatchCart) {
            this.unwatchCart();
        }
    },
    computed: {
        productUrl() {
            return route("products.show", {
                slug: this.product.slug,
                ...(this.hasAnyVariant && {
                    variant: this.item.uid,
                }),
            });
        },

        hasAnyVariant() {
            return this.product.variant !== null;
        },

        hasAnyOption() {
            return this.product.options_count > 0;
        },

        hasNoOption() {
            return !this.hasAnyOption;
        },

        hasAnyMedia() {
            return this.item.media.length !== 0;
        },

        hasBaseImage() {
            return this.item.base_image.length !== 0;
        },

        baseImage() {
            return this.hasBaseImage
                ? this.item.base_image.path
                : `${window.AyanCart.baseUrl}/build/assets/image-placeholder.png`;
        },

        inWishlist() {
            return store.inWishlist(this.product.id);
        },

        inCompareList() {
            return store.inCompareList(this.product.id);
        },
        pricePerUnit() {
            const price = this.product.selling_price.amount || this.product.price.amount;
            const price_per_unit = (price / this.product.unit_value).toFixed(2);
            return price_per_unit || ''
        }

    },

    methods: {
        syncWishlist() {
            store.syncWishlist(this.product.id);
        },

        syncCompareList() {
            store.syncCompareList(this.product.id);
        },

        addToCart() {
            this.addingToCart = true;
            return new Promise((resolve, reject) => {

                axios
                    .post(
                        route("cart.items.store", {
                            product_id: this.product.id,
                            qty: 1,
                            ...(this.hasAnyVariant && {
                                variant_id: this.item.id,
                            }),
                        })
                    )
                    .then((response) => {
                        store.updateCart(response.data);
                        if (document.location.href !== route("cart.index")) {
                            // $(".header-cart").trigger("click");
                        }
                        resolve(response);
                    })
                    .catch((error) => {
                        this.$notify(error.response.data.message);
                        reject(error);
                    })
                    .finally(() => {
                        this.addingToCart = false;
                    });
            });
        },

     

        updateQuantity(qty) {
            this.addingToCart = true;
            if (qty < 1) {
                this.removeCartItem();
                return;
            } else if (
                this.product.max_qty_per_order &&
                qty > this.product.max_qty_per_order
            ) {
                qty = this.product.max_qty_per_order;
                // notify user
                this.$notify("You can't add more than " + qty + " items");
            }

            axios
                .put(
                    route("cart.items.update", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    }),
                    {
                        qty: qty,
                    }
                )
                .then((response) => {
                    store.updateCart(response.data);
                    this.cartItem = response.data.items[this.cartItem.id];
                })
                .catch((error) => {
                    this.$notify('Something went wrong. Please try again. [ERROR_PC221]');
                }).finally(() => {
                    this.addingToCart = false;
                });
        },

        maxQuantity({ item }) {
            return item.is_in_stock && item.does_manage_stock ? item.qty : null;
        },
        isQtyIncreaseDisabled(cartItem) {
            return (
                this.maxQuantity(cartItem) !== null &&
                cartItem.qty >= cartItem.item.qty
            );
        },

        changeQuantity(qty) {

            if (isNaN(qty)) {
                qty = 1;

                this.updateQuantity(qty);

                return;
            } else if (qty < 1) {
                qty = 1;

                this.removeCartItem();

                return;
            } else if (qty > this.product.max_qty_per_order) {

                qty = this.product.max_qty_per_order;

                this.updateQuantity(qty);
                this.$notify("You can't add more than " + qty + " items")
                // alert("You can't add more than " + qty + " items")

                return;
            }

            this.cartItem.qty = qty;
            if (this.exceedsMaxStock(qty, this.cartItem)) {
                qty = this.cartItem.item.qty;
                // alert("Max stock is " + qty + " items")
                this.$notify("Max stock is " + qty + " items")
                this.updateQuantity(qty);

                return;
            }

            this.updateQuantity(qty);
        },

        removeCartItem() {
            store.removeCartItem(this.cartItem);

            axios
                .delete(
                    route("cart.items.destroy", {
                        id: this.cartItem.id,
                        ...(store.hasCoupon() && {
                            coupon_code: store.getCoupon(),
                        }),
                    })
                )
                .then((response) => {
                    store.updateCart(response.data);
                }).finally(() => {
                    this.addingToCart = false;
                });

            this.cartItem = false;
        },
        exceedsMaxStock(qty, cartItem) {
            return qty > cartItem.item.qty;
        },
        async add() {
            await this.addToCart();
            this.cartItem = store.getProductFromCart();
        },
      
        // update update area or status
        async updateProductArea(){
            const areaPattern = /^[A-Z]-\d{3}-\d-[A-Z]$/;
            this.item.area = this.item.area.toUpperCase();
            if (!areaPattern.test(this.item.area)) {
                this.$notify("Area must follow the pattern: [A-Z]-\\d{3}-\\d-[A-Z]", { type: "error" });
                return;
            }

            await axios
                .put(
                    route("products.update", {
                        product: this.product.id,
                    }),
                    {
                        area: this.item.area,
                    }
                )
                .then((response) => {
                    this.$notify(response.data.message, { type: "success" });
                })
                .catch((error) => {
                    this.$notify(error.response.data.message, { type: "error" });
                });
        }
    },
};
